import request from '@/utils/request'

export function initCorrectionFeedback (data) {
  return request({
    url: '/ErrorCorrectionFeedback/selectAllErrorCorrectionFeedback',
    method: 'POST',
    data
  })
}

export function details (data) {
  return request({
    url: '/ErrorCorrectionFeedback/details',
    method: 'POST',
    data
  })
}

export function updateStatus (data) {
  return request({
    url: '/ErrorCorrectionFeedback/updateStatus',
    method: 'POST',
    data
  })
}

// 问题反馈
export function initProblemFeedback (data) {
  return request({
    url: '/QuestionFeedback/selectAllQuestionFeedback',
    method: 'POST',
    data
  })
}

export function updateQuestionFeedbackStatus (data) {
  return request({
    url: '/QuestionFeedback/updateQuestionFeedbackStatus',
    method: 'POST',
    data
  })
}

export function questionFeedbackDetails (data) {
  return request({
    url: '/QuestionFeedback/detailsQuestionFeedback',
    method: 'POST',
    data
  })
}
